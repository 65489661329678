import axios from 'axios';
import { getApiBaseUrl, getAuthApiBaseUrl } from 'utils/env-variables';

const baseAuthUrl = getAuthApiBaseUrl();
const baseURL = getApiBaseUrl();
const token = sessionStorage.getItem('msal.idtoken') ?? '';

export const api = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${token}`
  }
});

export const authApi = axios.create({
  baseURL: baseAuthUrl,
  headers: {
    Authorization: `Bearer ${token}`
  }
});

// update this when we have a toast component in place to notify of errors
api.interceptors.response
  .use
  // response => {
  //   const data = response.data;
  //   console.log('response:', response);
  //   if (response.status === 200) {
  //     return data;
  //   }
  //   notification.error({
  //     message: `unexpected status code ${response.statusText}: ${response}`,
  //     description: data || response.statusText || 'Error'
  //   });
  //   if (response.status === 401) {
  //     window.location.href = '/login';
  //   }
  //   return Promise.reject(new Error(response.statusText || 'Error'));
  // },
  // error => {
  //   console.log('err:', error, error.response);
  //   if (error.response?.status) {
  //     switch (error.response.status) {
  //       case 401:
  //         window.location.href = '/login';
  //         break;
  //       case 403:
  //         window.location.href = '/login';
  //         break;
  //       case 404:
  //         notification.error({
  //           message: `not found`,
  //           description: error.response.data?.msg || 'Error'
  //         });
  //         break;
  //       case 406:
  //         notification.error({
  //           message: `not acceptable`,
  //           description: error.response.data?.msg || 'Error'
  //         });
  //         break;
  //       default:
  //         notification.error({
  //           message: `internal server error`,
  //           description: error.response.data?.msg || 'Error'
  //         });
  //     }
  //   }
  // return Promise.reject(error);
  // }
  ();
