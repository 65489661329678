import { useQueryClient } from '@tanstack/react-query';
import { useLoginQuery } from 'queries/useLoginQuery';
import { FC, ReactNode, createContext, useEffect, useState, useMemo } from 'react';
import { authApi } from 'utils/fetch';

export interface BankIdUser {
  pin: string;
  firstName: string;
  lastName: string;
}

interface Auth {
  user: BankIdUser | null;
  signIn: (user: BankIdUser) => void;
  signOut: () => void;
  mockSignIn: (user: BankIdUser) => void;
}

interface Props {
  children?: ReactNode;
}

export const AuthContext = createContext<Auth>({
  user: null,
  signIn: () => {
    console.warn('signIn has been called without a Context Provider');
  },
  signOut: () => {
    console.warn('signOut has been called without a Context Provider');
  },
  mockSignIn: () => {
    console.warn('signOut has been called without a Context Provider');
  }
});

export const AuthProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState<BankIdUser | null>(getUserLocaleStorage());
  const [isMockLogin, setIsMockLogin] = useState<boolean>(false);
  const { data: loggedInUser } = useLoginQuery();

  useEffect(( )=> {
    if(loggedInUser){
      signIn(loggedInUser)
    }
  }, [loggedInUser])

  const signOut = () => {
    setUser(null);
    authApi.post(`/signout`);
    queryClient.invalidateQueries({ queryKey: ['loginStatus'] });
    localStorage.removeItem('user');
  };

  const signIn = (user: BankIdUser) => {
    setIsMockLogin(false);
    setUser(user);
    localStorage.setItem('user', JSON.stringify(user));
  };

  const mockSignIn = (user: BankIdUser) => {
    setIsMockLogin(true);
    setUser(user);
    localStorage.setItem('user', JSON.stringify(user));
  };

  function getUserLocaleStorage(): BankIdUser | null {
    const userObjectString = localStorage.getItem('user');

    if (typeof userObjectString !== 'string') {
      return null;
    }

    return JSON.parse(userObjectString);
  }

  const queryClient = useQueryClient();

  useEffect(() => {
    if(!isMockLogin){
      queryClient.invalidateQueries({ queryKey: ['meetings', user?.pin] });
    }
  }, [user?.pin, queryClient, isMockLogin]);

  const memoExports = useMemo(() => ({ user, signIn, signOut, mockSignIn}), [user]);

  return <AuthContext.Provider value={memoExports}>{children}</AuthContext.Provider>;
};
