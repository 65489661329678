enum Origin {
    Local = 'https://localhost:',
    Dev = 'https://web-eoc-dw-dip-4511-gmt.azurewebsites.net',
    Test = 'https://web-eoc-tw-dip-4893-gmt.azurewebsites.net',
    PPE = 'https://web-eoc-hw-dip-4891-gmt.azurewebsites.net',
    Prod = 'https://web-eoc-pw-dip-4892-gmt.azurewebsites.net'
}

enum ApiUrl {
  Local = 'https://localhost:44388',
  Dev   = 'https://web-eoc-dw-dip-4511-gmt.azurewebsites.net',
  Test  = 'https://t2036-anz-api.testeuroclear.com/bol',
  PPE   = 'https://test-vantage-api.euroclear.com/bol',
  Prod  = 'https://vantage-api.euroclear.com/bol'
}

export const getApiBaseUrl = () => {
    const origin = window.location.origin;
    const port = window.location.port;

  switch (origin) {
    case Origin.Local + port:
      return buildApiUrl(ApiUrl.Local);
    case Origin.Dev:
      return buildApiUrl(ApiUrl.Dev);
    case Origin.Test:
      return buildApiUrl(ApiUrl.Test);
    case Origin.PPE:
      return buildApiUrl(ApiUrl.PPE);
    case Origin.Prod:
      return buildApiUrl(ApiUrl.Prod);
    default:
      return buildApiUrl(ApiUrl.Dev);
  }
};

export const getAuthApiBaseUrl = () => {
    const origin = window.location.origin;
    const port = window.location.port;

  switch (origin) {
    case Origin.Local + port:
      return buildAuthApiUrl(ApiUrl.Local);
    case Origin.Dev:
      return buildAuthApiUrl(ApiUrl.Dev);
    case Origin.Test:
      return buildAuthApiUrl(ApiUrl.Test);
    case Origin.PPE:
      return buildAuthApiUrl(ApiUrl.PPE);
    case Origin.Prod:
      return buildAuthApiUrl(ApiUrl.Prod);
    default:
      return buildAuthApiUrl(ApiUrl.Dev);
  }
};

const buildApiUrl = (url: ApiUrl) => {
  return `${url}/api`;
};
const buildAuthApiUrl = (url: ApiUrl) => {
  return `${url}/auth`;
};
