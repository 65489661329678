import { Autocomplete, Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AuthModal } from 'components/AuthModal';
import { BankIdCard } from 'components/BankIdCard';
import { MeetingsList } from 'components/MeetingList';
import { MeetingsInfo } from 'components/MeetingsInfo';
import { RelatedContent } from 'components/RelatedContent';
import { useAuth } from 'hooks/useAuth';
import { useFilterMeetings } from 'hooks/useFilterMeetings';
import { useMeetingsQuery } from 'queries/useMeetingsQuery';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortBy, Status, Type } from 'types/meeting';

const MeetingsPage = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { data: meetings, isLoading } = useMeetingsQuery(user?.pin);

  const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string | null>(null);
  const [meetingStatus, setMeetingStatus] = useState<Status>(Status.Ongoing);
  const [meetingType, setMeetingType] = useState<Type>(Type.AGM);
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.ClientName);
  const filters = { meetingStatus, meetingType };
  const { filteredMeetings } = useFilterMeetings({ meetings, searchString, filters, sortBy });

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={8}>
          <MeetingsInfo />
        </Grid>
        <Grid xs={12} sm={12} md={4}>
          <Stack gap={2}>
            <RelatedContent />
            <BankIdCard handleOpenAuthModal={() => setIsAuthModalOpen(true)} />
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid xs={12} sm={12} md={5}>
            <Autocomplete
              size="small"
              options={meetings?.map(meeting => meeting.clientName) ?? []}
              renderInput={params => (
                <TextField {...params} placeholder={t('meetings_page.search_field_placeholder')} />
              )}
              value={searchString || null}
              onInputChange={(_e, value: string | null) => setSearchString(value)}
            />
          </Grid>

          <Grid xs={4} sm={4} md={2}>
            <FormControl fullWidth>
              <InputLabel>{t('meetings_page.status_select_label')}</InputLabel>
              <Select
                size="small"
                value={meetingStatus}
                onChange={e => setMeetingStatus(e.target.value as Status)}
                label={t('meetings_page.status_select_label')}
                fullWidth
              >
                <MenuItem value={Status.Ongoing}>{t('meetings_page.status_select_ongoing')}</MenuItem>
                <MenuItem value={Status.Completed}>{t('meetings_page.status_select_completed')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={4} sm={4} md={2}>
            <FormControl fullWidth>
              <InputLabel>{t('meetings_page.type_select_label')}</InputLabel>
              <Select
                label={t('meetings_page.type_select_label')}
                size="small"
                value={meetingType}
                onChange={e => setMeetingType(e.target.value as Type)}
                fullWidth
              >
                <MenuItem value={Type.AGM}>{t('meetings_page.type_select_annual')}</MenuItem>
                <MenuItem value={Type.EGM}>{t('meetings_page.type_select_extra')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={4} sm={4} md={3}>
            <FormControl fullWidth>
              <InputLabel>{t('meetings_page.sort_select_label')}</InputLabel>
              <Select
                label={t('meetings_page.sort_select_label')}
                size="small"
                value={sortBy}
                onChange={e => setSortBy(e.target.value as SortBy)}
                fullWidth
              >
                <MenuItem value={SortBy.ClientName}>{t('meetings_page.sort_select_name')}</MenuItem>
                <MenuItem value={SortBy.MeetingDate}>{t('meetings_page.sort_select_meeting_date')}</MenuItem>
                <MenuItem value={SortBy.DeadlineNotification}>
                  {t('meetings_page.sort_select_notification_date')}
                </MenuItem>
                <MenuItem value={SortBy.DeadlinePostalVote}>
                  {t('meetings_page.sort_select_postal_voting_date')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <MeetingsList meetings={filteredMeetings} isLoading={isLoading} />
      </Stack>
      <AuthModal open={isAuthModalOpen} handleClose={() => setIsAuthModalOpen(false)} />
    </>
    // </Container>
  );
};

export default MeetingsPage;
